
// Check that service workers are supported
if ('serviceWorker' in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(
      new URL('service-worker.js', import.meta.url),
      {type: 'module'}
  );
  });
}


//
// Keyboard shortcuts if detached search
//


if (document.querySelector('.aa-DetachedSearchButton')) {
  var Mousetrap = require('mousetrap');
  
  
    // map multiple combinations to the same callback
    Mousetrap.bind(['command+e', 'ctrl+e'], function() {
      // console.log('command e or control e');
      document.querySelector(".aa-DetachedSearchButton").click();
      // return false to prevent default browser behavior
      // and stop event from bubbling
      return false;
  });
  };

  

  // Share button
  initializeApp();
  async function onShare() {
    const title = document.title;
    const url = document.querySelector("link[rel=canonical]")
      ? document.querySelector("link[rel=canonical]").href
      : document.location.href;
    const text = "Quizás te interese:";
    try {
        await navigator
        .share({
          title,
          url,
          text
        })
        
          /*
            Show a message if the user shares something
          */
          console.log(`Gracias por compartir!`);
      } catch (err) {
         /*
            This error will appear if the user cancels the action of sharing.
          */
         console.log(`No se puede compartir - ${err}`);
      }
  }


  function initializeApp() {
    if ("serviceWorker" in navigator) {
        /*
          Since this is a PWA, I want to make sure that if the SW is registered then I keep 
          initializing things for the app, but having a service worker nor a PWA is needed
          to make use of the Web Share API. 
        */
          document.querySelector(".share-btn").addEventListener("click", onShare);
    }
  };
  

/**
 * Warn the page must be served over HTTPS
 * The `beforeinstallprompt` event won't fire if the page is served over HTTP.
 * Installability requires a service worker with a fetch event handler, and
 * if the page isn't served over HTTPS, the service worker won't load.
 */
if (window.location.protocol === 'http:') {
  const requireHTTPS = document.getElementById('requireHTTPS');
  const link = requireHTTPS.querySelector('a');
  link.href = window.location.href.replace('http://', 'https://');
  requireHTTPS.classList.remove('hidden');
};


// Prefetch URLs on mouseover and touchstart
function prefetch(e) {
  if (e.target.tagName != "A") {
    return;
  }
  if (e.target.origin != location.origin) {
    return;
  }
  /**
   * Return the given url with no fragment
   * @param {string} url potentially containing a fragment
   * @return {string} url without fragment
   */
  const removeUrlFragment = (url) => url.split("#")[0];
  if (removeUrlFragment(window.location.href) === removeUrlFragment(e.target.href)) {
    return;
  }
  var l = document.createElement("link");
  l.rel = "prefetch";
  l.href = e.target.href;
  document.head.appendChild(l);
}
document.documentElement.addEventListener("mouseover", prefetch, {
  capture: true,
  passive: true,
});
document.documentElement.addEventListener("touchstart", prefetch, {
  capture: true,
  passive: true,
});

// Check if img are loaded and stop placeholder blurry render
// There is a race condition here if an image loads faster than this JS file. But
// - that is unlikely
// - it only means potentially more costly layouts for that image.
// - And so it isn't worth the querySelectorAll it would cost to synchronously check
//   load state.
document.body.addEventListener(
  "load",
  (e) => {
    if (e.target.tagName != "IMG") {
      return;
    }
    // Ensure the browser doesn't try to draw the placeholder when the real image is present.
    e.target.style.backgroundImage = "none";
  },
  /* capture */ "true"
);